export const isValidUrl = (text: string): string => {
  if (text.indexOf(' ') !== -1) {
    return '';
  }

  let url;
  let protocol = '';

  try {
    url = new URL(text);
  } catch {
    try {
      protocol = 'https://';
      url = new URL(`${protocol}/${text}`);
    } catch {
      return '';
    }
  }

  const lastIndex = url.host.lastIndexOf('.');
  if (lastIndex < 0) {
    return '';
  }

  const domain = url.host.substring(url.host.lastIndexOf('.') + 1);

  if (
    new RegExp(
      '^((aero|app|arpa|asia|a[cdefgilmnoqrstuwxz])|(biz|b[abdefghijmnorstvwyz])|(cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(edu|e[cegrstu])|f[ijkmor]|(gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(info|int|i[delmnoqrst])|(jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(mil|mobi|museum|m[acdeghklmnopqrstuvwxyz])|(name|net|n[acefgilopruz])|(org|om)|(php|pro|p[aefghklmnrstwy])|qa|r[eouw]|(space|s[abcdeghijklmnortuvyz])|(tel|travel|t[cdfghjklmnoprtvwz])|(university|u[agkmsyz])|v[aceginu]|w[fs]|y[etu]|z[amw]|xn--p1ai|рф)$',
    ).test(domain)
  ) {
    return `${protocol}${text}`;
  }

  return '';
};
