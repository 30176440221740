<!-- TODO: refactoring заменить на contacts-view-island -->
<contacts-view-old
  [(value)]="value"
  [disabled]="disabled"
  [loading]="loading"
  [allowCreating]="allowCreating"
  [allowEditing]="allowEditing"
  [allowDeleting]="allowDeleting"
  [allowFilesDeleting]="allowFilesDeleting"
></contacts-view-old>
