<form class="form" [formGroup]="form">
  <div class="contacts" formArrayName="contacts">
    <tui-island *ngFor="let contact of contacts.controls; let cIndex = index" class="tui-island">
      <div class="tui-island__paragraph item" [formGroupName]="cIndex">
        <div class="contact">
          <!-- Тип поля -->
          <div *ngIf="allowEditing" class="contact__type">
            <tui-select
              tabindex="20"
              formControlName="contactTypeId"
              [valueContent]="contactTypes ? contactTypesStringify(contactTypes) : ''"
              [tuiTextfieldSize]="tuiElementLargeSize"
              [class.tui-skeleton]="loading"
              [class.tui-skeleton_text]="loading"
            >
              {{ 'components.userInfo.fields.contactType' | translate }}
              <tui-data-list *tuiDataList>
                <button *ngFor="let contactType of contactTypes" tuiOption [value]="contactType.id">
                  {{ contactType.description | translate }}
                </button>
              </tui-data-list>
            </tui-select>
          </div>

          <!-- Название поля -->
          <div *ngIf="allowEditing" class="contact__description">
            <tui-input
              class="title"
              tabindex="21"
              formControlName="description"
              [tuiTextfieldSize]="tuiElementLargeSize"
              [class.tui-skeleton]="loading"
              [class.tui-skeleton_text]="loading"
            >
              {{ 'components.userInfo.fields.contactName' | translate }}
            </tui-input>

            <app-field-wrapper-old
              *ngxPermissionsOnly="['contactsRequireValueEditing']"
              [label]="'common.labels.required' | translate"
              [class.tui-skeleton]="loading"
              [class.tui-skeleton_text]="loading"
              [size]="tuiElementMediumSize"
            >
              <app-toggle formControlName="isRequired" [size]="tuiElementLargeSize"></app-toggle>
            </app-field-wrapper-old>
          </div>

          <!-- Значение поля -->
          <div class="contact__value">
            <!-- Поле не является ни Прочим, ни файлом -->
            <tui-input
              *ngIf="isNotOtherAndNotFileContactLocal(contact.value.contactTypeId)"
              tabindex="22"
              formControlName="contact"
              [tuiTextfieldSize]="tuiElementLargeSize"
              [class.tui-skeleton]="loading"
              [class.tui-skeleton_text]="loading"
            >
              {{ allowEditing ? ('components.userInfo.fields.contactValue' | translate) : contact.value.description }}
            </tui-input>

            <!-- Поле является Прочим -->
            <tui-textarea
              *ngIf="isOtherContactLocal(contact.value.contactTypeId)"
              class="field_height_min"
              tabindex="22"
              formControlName="contact"
              [tuiTextfieldSize]="tuiElementLargeSize"
              [class.tui-skeleton]="loading"
              [class.tui-skeleton_text]="loading"
              [expandable]="true"
            >
              {{ allowEditing ? ('components.userInfo.fields.contactValue' | translate) : contact.value.description }}
            </tui-textarea>

            <!-- Поле является файлом -->
            <ng-container *ngIf="isFileContactLocal(contact.value.contactTypeId)">
              <app-label [label]="allowEditing ? '' : contact.value.description">
                <ng-container *tuiLet="getDocumentFormControl(contact, 'oldDocument').value as oldDocumentValue">
                  <tui-files *ngIf="!!oldDocumentValue">
                    <tui-file
                      [class.tui-skeleton]="loading"
                      [class.tui-skeleton_text]="loading"
                      [file]="oldDocumentValue"
                      (removed)="resetDocumentFormControl(contact, 'oldDocument')"
                      [showDelete]="!!allowFilesDeleting"
                    >
                      <app-download-link
                        class="download-button"
                        [fileId]="oldDocumentValue.id"
                        [showIcon]="false"
                        [pseudo]="false"
                      >
                        {{ 'common.buttons.download' | translate }}
                      </app-download-link>
                    </tui-file>
                  </tui-files>

                  <ng-container *ngIf="!oldDocumentValue">
                    <ng-container *tuiLet="getDocumentFormControl(contact, 'newDocument').value as newDocumentValue">
                      <tui-input-files
                        *ngIf="!newDocumentValue"
                        class="size_{{ tuiElementLargeSize }}"
                        [link]="'common.fields.fileLink' | translate"
                        [label]="' '"
                        tabindex="22"
                        formControlName="newDocument"
                        [maxFileSize]="maxDocFileSize"
                        [tuiTextfieldSize]="tuiElementLargeSize"
                        [class.tui-skeleton]="loading"
                        [class.tui-skeleton_text]="loading"
                        [size]="tuiElementLargeSize"
                      ></tui-input-files>
                      <tui-files *ngIf="!!newDocumentValue">
                        <tui-file
                          [file]="newDocumentValue"
                          (removed)="resetDocumentFormControl(contact, 'newDocument')"
                          [class.tui-skeleton]="loading"
                          [class.tui-skeleton_text]="loading"
                        ></tui-file>
                      </tui-files>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </app-label>
            </ng-container>
          </div>

          <!-- Номер для сортировки поля -->
          <tui-input-number
            *ngIf="allowEditing"
            class="contact__sortOrder"
            tabindex="23"
            formControlName="sortOrder"
            [tuiTextfieldSize]="tuiElementLargeSize"
            [class.tui-skeleton]="loading"
            [class.tui-skeleton_text]="loading"
          >
            {{ 'components.userInfo.fields.contactSortOrder' | translate }}
          </tui-input-number>
        </div>
      </div>

      <button
        *ngIf="allowDeleting"
        tuiButton
        type="button"
        appearance="flat"
        size="m"
        class="tui-island__footer-button"
        tabindex="24"
        [class.tui-skeleton]="loading"
        [class.tui-skeleton_text]="loading"
        [disabled]="contact.disabled"
        (click)="removeContact(cIndex)"
      >
        {{ 'common.buttons.delete' | translate }}
      </button>
    </tui-island>
  </div>
  <button
    *ngIf="!disabled && allowCreating"
    tuiButton
    type="button"
    appearance="primary"
    size="m"
    icon="tuiIconPlusCircleLarge"
    class="add-button"
    tabindex="25"
    [class.tui-skeleton]="loading"
    [class.tui-skeleton_text]="loading"
    (click)="addContact()"
  >
    {{ 'components.userInfo.buttons.addField' | translate }}
  </button>
</form>
