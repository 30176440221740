import { RoleCode } from './types';

/**
 * Кол-во секунд для автоматической перезагрузки страницы,
 * если при выходе из приложения что-то пошло не так
 */
export const AUTO_RELOAD_PAGE_TIMER = 5000;

export const ROLE_PERMISSIONS_DEFAULT: Record<RoleCode, readonly string[]> = {
  SysOp: [
    'pageAdministrationViewing',
    'pageAssociationViewing',
    'pageAssociationOrganisationsViewing',
    'pageAssociationUsersViewing',
    'pageNewsFeedViewing',
    'pageChatsViewing',
    'pageCommitteesViewing',
    'pageSubCommitteesViewing',
    'pageEventsViewing',
    'pageInfoViewing',
    'pageOrganisationViewing',
    'pagePollsViewing',
    'pageNewsletterViewing',
    'pageProfileViewing',
    'pageSettingsViewing',
    'pageSettingsFoldersViewing',
    'pageSettingsPreferencesViewing',
    'pageSettingsNotificationsViewing',
    'pageLoyaltyViewing',
    'pageBirthdaysViewing',
    'pageCooperationChainsViewing',

    'associationCreating',
    'associationEditing',
    'associationChannelSpecialFieldsViewing',
    'associationChannelAutoSubscribing',

    'chatsCreating',
    'chatInviteMembersViewing',

    'committeeCreating',
    'committeeDeleting',
    'committeeEditing',
    'committeeSpecialFieldsForAssociationEditing',

    'subCommitteeCreating',
    'subCommitteeDeleting',
    'subCommitteeEditing',
    'subCommitteeSpecialFieldsForAssociationViewing',
    'subCommitteeSpecialFieldsForAssociationEditing',

    'committeeChatsCreating',
    'committeeChatsEditing',

    'committeeMembersViewing',
    'committeeMembersAdding',
    'committeeMembersDeleting',
    'committeeConfigsViewing',

    'eventCreating',
    'eventEditing',
    'eventReportViewing',
    'eventMembersViewing',
    'eventsAllViewing',

    'importViewing',

    'organisationCreating',
    'organisationDeleting',
    'organisationEditing',
    'organisationSpecialFieldsViewing',
    'organisationSpecialFieldsEditing',
    'organisationSpecialFieldsForAssociationViewing',
    'organisationSpecialFieldsForAssociationEditing',
    'organisationConfigsViewing',

    'organisationChannelCreating',
    'organisationChannelEditing',
    'organisationChannelSubscribing',

    'organisationEmployeeViewing',
    'organisationEmployeeCreating',
    'organisationEmployeeEditing',
    'organisationEmployeeDeleting',
    'organisationEmployeePhoneFieldViewing',
    'organisationEmployeeEmailFieldViewing',
    'organisationEmployeeRoleFieldViewing',
    'organisationEmployeeSpecialFieldsViewing',
    'organisationEmployeeSpecialFieldsEditing',
    'organisationEmployeeSpecialFieldsForAssociationViewing',
    'organisationEmployeeSpecialFieldsForAssociationEditing',
    'organisationEmployeeJobTitleFieldsEditing',
    'organisationEmployeeJobTitlesForAdminOnlyEditing',

    'pollCreating',
    'pollEditing',
    'pollMembersViewing',
    'pollStatisticsViewing',
    'pollsAllViewing',

    'newsletterCreating',
    'newsletterEditing',

    'preferencesEditing',

    'loyaltyProgramCreating',
    'loyaltyProgramEditing',
    'loyaltyProgramDeleting',
    'loyaltyProgramPublishing',
    'loyaltyProgramUnpublishing',
    'loyaltyProgramOwnProductsViewing',
    'loyaltyProgramAllStatisticsViewing',
    'loyaltyProgramUnionListViewing',

    'synchronizeWithTelegram',

    'contactsTypeForAdminViewing',
    'contactsCreating',
    'contactsEditing',
    'contactsDeleting',
    'contactsRequireValueEditing',
  ],
  AdminUO: [
    'pageAdministrationViewing',
    'pageAssociationViewing',
    'pageAssociationOrganisationsViewing',
    'pageAssociationUsersViewing',
    'pageNewsFeedViewing',
    'pageChatsViewing',
    'pageCommitteesViewing',
    'pageSubCommitteesViewing',
    'pageEventsViewing',
    'pageInfoViewing',
    'pageOrganisationViewing',
    'pagePollsViewing',
    'pageNewsletterViewing',
    'pageProfileViewing',
    'pageSettingsViewing',
    'pageSettingsFoldersViewing',
    'pageSettingsPreferencesViewing',
    'pageSettingsNotificationsViewing',
    'pageLoyaltyViewing',
    'pageBirthdaysViewing',
    'pageCooperationChainsViewing',

    'associationEditing',
    'associationChannelSpecialFieldsViewing',
    'associationChannelAutoSubscribing',

    'chatsCreating',
    'chatInviteMembersViewing',

    'committeeCreating',
    'committeeDeleting',
    'committeeEditing',
    'committeeSpecialFieldsForAssociationEditing',

    'subCommitteeCreating',
    'subCommitteeDeleting',
    'subCommitteeEditing',
    'subCommitteeSpecialFieldsForAssociationViewing',
    'subCommitteeSpecialFieldsForAssociationEditing',

    'committeeChatsCreating',
    'committeeChatsEditing',

    'committeeMembersViewing',
    'committeeMembersAdding',
    'committeeMembersDeleting',
    'committeeConfigsViewing',

    'eventCreating',
    'eventEditing',
    'eventReportViewing',
    'eventMembersViewing',
    'eventsAllViewing',

    'importViewing',

    'organisationCreating',
    'organisationDeleting',
    'organisationEditing',
    'organisationSpecialFieldsViewing',
    'organisationSpecialFieldsEditing',
    'organisationSpecialFieldsForAssociationViewing',
    'organisationSpecialFieldsForAssociationEditing',
    'organisationConfigsViewing',

    'organisationChannelCreating',
    'organisationChannelEditing',
    'organisationChannelSubscribing',

    'organisationEmployeeViewing',
    'organisationEmployeeCreating',
    'organisationEmployeeEditing',
    'organisationEmployeeDeleting',
    'organisationEmployeePhoneFieldViewing',
    'organisationEmployeeEmailFieldViewing',
    'organisationEmployeeRoleFieldViewing',
    'organisationEmployeeSpecialFieldsViewing',
    'organisationEmployeeSpecialFieldsEditing',
    'organisationEmployeeSpecialFieldsForAssociationViewing',
    'organisationEmployeeSpecialFieldsForAssociationEditing',
    'organisationEmployeeJobTitleFieldsEditing',
    'organisationEmployeeJobTitlesForAdminOnlyEditing',

    'pollCreating',
    'pollEditing',
    'pollMembersViewing',
    'pollStatisticsViewing',
    'pollsAllViewing',

    'newsletterCreating',
    'newsletterEditing',

    'preferencesEditing',

    'loyaltyProgramCreating',
    'loyaltyProgramEditing',
    'loyaltyProgramDeleting',
    'loyaltyProgramPublishing',
    'loyaltyProgramUnpublishing',
    'loyaltyProgramOwnProductsViewing',
    'loyaltyProgramAllStatisticsViewing',
    'loyaltyProgramUnionListViewing',

    'synchronizeWithTelegram',

    'contactsTypeForAdminViewing',
    'contactsCreating',
    'contactsEditing',
    'contactsDeleting',
    'contactsRequireValueEditing',
  ],
  AdminDO: [
    'pageAssociationViewing',
    'pageAssociationOrganisationsViewing',
    'pageAssociationUsersViewing',
    'pageNewsFeedViewing',
    'pageChatsViewing',
    'pageCommitteesViewing',
    'pageSubCommitteesViewing',
    'pageEventsViewing',
    'pageInfoViewing',
    'pageOrganisationViewing',
    'pagePollsViewing',
    'pageNewsletterViewing',
    'pageProfileViewing',
    'pageSettingsViewing',
    'pageSettingsFoldersViewing',
    'pageSettingsNotificationsViewing',
    'pageLoyaltyViewing',
    'pageBirthdaysViewing',
    'pageCooperationChainsViewing',

    'associationChannelAutoSubscribing',

    'committeeMembersViewing',

    // TODO: временное решение
    'eventCreating',
    'eventHideFieldsEditing',
    'eventMembersViewing',
    'onlyYourEventEditing',

    'onlyYourOrganisationEditing',
    'onlyYourOrganisationSpecialFieldsViewing',
    'onlyYourOrganisationSpecialFieldsEditing',
    'onlyYourOrganisationSpecialFieldsForAssociationViewing',

    'onlyYourOrganisationChannelCreating',
    'onlyYourOrganisationChannelEditing',
    'organisationChannelSubscribing',

    'organisationEmployeeViewing',
    'onlyYourOrganisationEmployeeEditing',
    'onlyYourOrganisationEmployeeDeleting',
    'onlyYourOrganisationEmployeeSpecialFieldsViewing',
    'onlyYourOrganisationEmployeeSpecialFieldsEditing',
    'onlyYourOrganisationEmployeeJobTitleFieldsEditing',

    'loyaltyProgramCreating',
    'loyaltyProgramOwnProductsViewing',
    'loyaltyProgramAllStatisticsViewing',
    'loyaltyProgramUnionListViewing',

    'onlyYourOrganisationContactsCreating',
    'onlyYourOrganisationContactsEditing',
    'onlyYourOrganisationContactsDeleting',

    'onlyYourOrganisationEmployeeContactsCreating',
    'onlyYourOrganisationEmployeeContactsEditing',
    'onlyYourOrganisationEmployeeContactsDeleting',

    'onlyYourJobTitleFieldsEditing',
  ],
  ParticipantUO: [
    'pageAssociationViewing',
    'pageAssociationOrganisationsViewing',
    'pageAssociationUsersViewing',
    'pageNewsFeedViewing',
    'pageChatsViewing',
    'pageCommitteesViewing',
    'pageSubCommitteesViewing',
    'pageEventsViewing',
    'pageInfoViewing',
    'pageOrganisationViewing',
    'pagePollsViewing',
    'pageNewsletterViewing',
    'pageProfileViewing',
    'pageSettingsViewing',
    'pageSettingsFoldersViewing',
    'pageSettingsNotificationsViewing',
    'pageLoyaltyViewing',
    'pageBirthdaysViewing',

    'associationChannelAutoSubscribing',

    'committeeMembersViewing',

    'eventCreating',
    'eventMembersViewing',
    'onlyYourEventEditing',

    'organisationChannelSubscribing',

    'organisationEmployeeViewing',

    'pollCreating',
    'onlyYourPollEditing',
    'onlyYourPollStatisticsViewing',

    'onlyYourJobTitleFieldsEditing',
  ],
  ParticipantDO: [
    'pageAssociationViewing',
    'pageAssociationOrganisationsViewing',
    'pageAssociationUsersViewing',
    'pageNewsFeedViewing',
    'pageChatsViewing',
    'pageCommitteesViewing',
    'pageSubCommitteesViewing',
    'pageEventsViewing',
    'pageInfoViewing',
    'pageOrganisationViewing',
    'pagePollsViewing',
    'pageNewsletterViewing',
    'pageProfileViewing',
    'pageSettingsViewing',
    'pageSettingsFoldersViewing',
    'pageSettingsNotificationsViewing',
    'pageLoyaltyViewing',
    'pageBirthdaysViewing',

    'associationChannelAutoSubscribing',

    'committeeMembersViewing',

    'eventMembersViewing',

    'onlyYourOrganisationSpecialFieldsViewing',

    'organisationChannelSubscribing',

    'organisationEmployeeViewing',

    'onlyYourJobTitleFieldsEditing',
  ],
  Spectator: [
    'pageAssociationViewing',
    'pageAssociationOrganisationsViewing',
    'pageNewsFeedViewing',
    'pageChatsViewing',
    'pageCommitteesViewing',
    'pageSubCommitteesViewing',
    'pageEventsViewing',
    'pageInfoViewing',
    'pageOrganisationViewing',
    'pagePollsViewing',
    'pageNewsletterViewing',
    'pageProfileViewing',
    'pageSettingsViewing',
    'pageSettingsFoldersViewing',
    'pageSettingsNotificationsViewing',
    'pageLoyaltyViewing',

    'onlyYourOrganisationEmployeeViewing',

    'onlyYourJobTitleFieldsEditing',
  ],
} as const;
