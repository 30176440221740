<div class="new-users">
  <div class="new-users__content content">
    <div [hide]="!showFullPage" class="content__list">
      <app-list
        class="list"
        listType="users"
        [items]="unregistredUsers$ | async"
        [selectedId]="selectedUserId"
        [withCounter]="true"
        (selectedIdChange)="changeSelectedUserId($event)"
      ></app-list>
    </div>

    <tui-scrollbar *ngIf="selectedUserId" class="content__content">
      <app-user-info
        mode="view"
        [userId]="selectedUserId"
        (saved)="onSaveEditingUser()"
        (deleted)="onDeleteUser($event)"
      ></app-user-info>
    </tui-scrollbar>
  </div>
</div>
